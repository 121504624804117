import { DocumentNode, gql } from '@apollo/client';

export function getAllDocumentsByCorrelationIdQuery(correlationId: string): DocumentNode {
  return gql`
    query {
      allDocumentsByCorrelationId(correlationId: "${correlationId}") {
        id
        data
      }
    }
  `;
}
