import { DocumentNode, gql } from '@apollo/client';

export function getRecyclingProcessesByFilterQuery(startDate: string, endDate: string, hideInactiveProcesses: boolean, searchInput: string, position: number, take: number): DocumentNode {
  return gql`
    query {
      recyclingProcessesByFilter(startDate: "${startDate}", endDate: "${endDate}", hideInactiveProcesses: ${hideInactiveProcesses}, searchInput: "${searchInput}", position: ${position}, take: ${take}) {
        id
        correlationId
        processStart
        chosenProductCategory {
          id
          name
        }
        chosenManufacturer
        country {
          id
          name
          countryCode
          logisticTypeId
          localPartnerEmail
        }
        trackingId
        dateOfArrival
        dateOfCancellation
        arrivedProductCategory {
          id
          name
          isOther
        }
        arrivedProductCategoryOther
        serialNumber
      }
    }
  `;
}
