export function parseCountryFromUrl(url: string): string {

  // for collection-NL e.g.
  /* const regEx = new RegExp('(collection)-([a-zA-Z]{2})'); */

  const regEx = new RegExp('((country)=([a-zA-Z]{2}))');

  let matches: RegExpExecArray | null = null;

  if (regEx.test(url)) {
    matches = regEx.exec(url);
  }

  return matches === null ? '' : matches[3].toUpperCase();
}
