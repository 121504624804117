import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { Config } from '../../components';
import { LogisticType } from '../../contracts/LogisticType';
import { ErrorHandler } from '../../util';
import { getAllLogisticTypesQuery } from './Queries';

export class LogisticTypeApolloClient {

  private apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(identityToken: string, config: Config) {
    const httpLink = createHttpLink({
      uri: config.webAPIConfig.graphQlHttpApiUrl,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: identityToken ? `Bearer ${identityToken}` : '',
      },
    }));

    this.apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  public async getAllLogisticTypes(): Promise<Array<LogisticType>> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getAllLogisticTypesQuery,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.logisticTypes;
    } catch (error) {
      ErrorHandler.handleError(error);
      return [];
    }
  }
}
