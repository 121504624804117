import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';

import App from './App';
import '../src/index.css';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Could not find root element');
}
const root = createRoot(container);

root.render(<App />);
