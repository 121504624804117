import React from 'react';
import i18n from '../../../util/i18n';
import { DoorstepCollectionError } from './DoorstepCollectionError.enum';
import { DoorstepCollectionErrorModalProps } from './DoorstepCollectionErrorModalProps';

export class DoorstepCollectionErrorModal extends React.Component<DoorstepCollectionErrorModalProps> {

  private errorType: DoorstepCollectionError;

  constructor(props: DoorstepCollectionErrorModalProps) {
    super(props);

    const { errorType } = props;
    this.errorType = errorType;
  }

  public render(): React.ReactNode {
    return (
      <div className='fixed z-10 inset-0 overflow-y-auto flex items-center justify-center'>
        <div className='transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75' />
        </div>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
        <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          <div>
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100'>
              <svg className='h-6 w-6 text-red-600'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </div>
            <div className='mt-3 text-center sm:mt-5'>
              <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
                {i18n.t('DoorstepCollectionErrorModal_Header')}
              </h3>
              <div className='mt-2 flex flex-col gap-2'>
                <p className='text-sm text-gray-500'>
                  {i18n.t(`DoorstepCollectionErrorModal_${DoorstepCollectionError.ADDRESS}_Body`)}
                </p>
                <p className='text-sm text-gray-500'>
                  {i18n.t(`DoorstepCollectionErrorModal_${DoorstepCollectionError.TIME}_Body`)}
                </p>
              </div>
            </div>
          </div>
          <div className='mt-5 sm:mt-6'>
            <button
              className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
              onClick={this.props.toggleModal}
            >
              {i18n.t('OK')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
