import { DocumentNode, gql } from '@apollo/client';

export function getProductCategoriesForCustomerPortalQuery(isOther: boolean): DocumentNode {
  return gql`
    query {
      productCategoriesForCustomerPortal(isOther: ${isOther}) {
        id,
        name,
        packageWeight,
        isOther,
        commodityCode,
        invoiceValue
      }
    }
  `;
}
