import React from 'react';
import { ProductCategoryComponentProps, ProductCategoryComponentState } from './contracts';

export class ProductCategoryComponent extends React.Component<ProductCategoryComponentProps, ProductCategoryComponentState> {

  constructor(props: any) {
    super(props);

    this.state = {
      productCategories: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    const allProductCategories = await this.props.productCategoryApolloClient.getAllProductCategories();

    this.setState({
      productCategories: allProductCategories,
    });
  }

  public render(): JSX.Element {
    if (this.state.productCategories !== null) {
      return (
        <div className='ProductCategoryTab'>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>PackageWeight</th>
                <th>IsOther</th>
              </tr>
            </thead>
            {this.state.productCategories.map((productCategory) => (
              <tbody key={productCategory.id}>
                <tr>
                  <td>{productCategory.id}</td>
                  <td>{productCategory.name}</td>
                  <td>{productCategory.packageWeight}</td>
                  <td>{String(productCategory.isOther)}</td>
                </tr>
              </tbody>
            ))
            }
          </table>
        </div>
      );
    } else {
      return <></>;
    }
  }
}
