import { gql } from '@apollo/client';

export const getAllLogisticTypesQuery = gql`
{
  logisticTypes {
    id,
    name,
  }
}
`;
