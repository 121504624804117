import React from 'react';

import { DataModels, Identity } from '@atlas-engine/atlas_engine_client';
import { CustomFormService, FormState } from '@atlas-engine-contrib/atlas-ui_sdk';

import { Config } from '../contracts/Config';
import { IncomingPackageCustomForm, SuccessPageCustomForm, TesMailDeliveredCustomForm } from '../../../features';
import { CustomerMailDeliveredCustomForm } from '../../../features';
import { ConfigService } from '../../../api/config';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomFormProps } from './contracts/CustomFormProps';
import { CustomFormRendererProps } from './contracts/CustomFormRendererProps';
import { CustomFormsServiceState } from './contracts/CustomFormsServiceState';
import { CustomFormsComponentDict } from './contracts/CustomFormsComponentDict';

class CustomFormsRenderer extends React.Component<CustomFormRendererProps & WithTranslation, CustomFormsServiceState> {

  private readonly configService = new ConfigService();
  private config?: Config;

  constructor(props: CustomFormRendererProps & WithTranslation) {
    super(props);

    this.configService.getConfig().then((config) => {
      this.config = config as Config;
      this.setState({ config: config });
    });
  }

  public state: CustomFormsServiceState = {
    targetComponent: null,
    userTask: null,
    suspendState: null,
    currentLanguage: 'de',
    targetIdentity: null,
  };

  private components: CustomFormsComponentDict = {
    successPageCustomForm: SuccessPageCustomForm,
    tesMailDeliveredCustomForm: TesMailDeliveredCustomForm,
    incomingPackageCustomForm: IncomingPackageCustomForm,
    customerMailDeliveredCustomForm: CustomerMailDeliveredCustomForm,
  };

  private customFormService: CustomFormService | null = null;

  public componentDidMount(): void {
    this.customFormService = new CustomFormService();
    this.customFormService.onUserTaskReceived(this.updateComponent);

    this.components = {
      ...this.components,
      ...this.props.components,
    };
  }

  public updateComponent = (
    userTask: DataModels.FlowNodeInstances.UserTaskInstance,
    _identity: Identity,
    suspendState: FormState | null,
  ): void => {

    const preferredControl = userTask.userTaskConfig.customForm;
    if (!preferredControl) {
      throw new Error(`No preferredControl set in usertask: ${JSON.stringify(userTask)}`);
    }

    const component = this.components[preferredControl];
    if (!component) {
      throw new Error(`No component found for usertask ${JSON.stringify(userTask)}`);
    }

    this.setState({
      targetComponent: component,
      userTask: userTask,
      suspendState: suspendState,
      targetIdentity: _identity,
      config: this.config,
    });
  };

  public componentWillUnmount(): void {
    if (!this.customFormService) {
      return;
    }
    this.customFormService.destroy();
  }

  public suspendUserTask = (state: FormState): void => {
    this.customFormService?.suspendUserTask(state);
  };

  public abortUserTask = (): void => {
    this.customFormService?.terminateProcessInstance();
  };

  public finishUserTask = (result: DataModels.FlowNodeInstances.UserTaskResult): void => {
    this.customFormService?.finishUserTask(result);
  };

  public render(): JSX.Element | null {
    if (!this.state.targetComponent || !this.state.userTask || !this.state.targetIdentity || !this.state.config) {
      return null;
    }

    const componentInstance = React.createElement(this.state.targetComponent, {
      userTask: this.state.userTask,
      suspendState: this.state.suspendState,
      suspendUserTask: this.suspendUserTask,
      abortUserTask: this.abortUserTask,
      finishUserTask: this.finishUserTask,
      identity: this.state.targetIdentity,
      config: this.state.config,
    } as CustomFormProps);

    return componentInstance;
  }

}

export default withTranslation()(CustomFormsRenderer);
