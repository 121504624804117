import { DocumentNode, gql } from '@apollo/client';

export function getRecyclingProcessByCorrelationIdQuery(correlationId: string): DocumentNode {
  return gql`
    query {
      recyclingProcessByCorrelationId(correlationId: "${correlationId}") {
        id
        correlationId
        processStart
        chosenProductCategory {
          id
          name
        }
        chosenManufacturer
        country {
          id
          name
          countryCode
          logisticTypeId
          localPartnerEmail
        }
        trackingId
        dateOfArrival
        dateOfCancellation
        arrivedProductCategory {
          id
          name
        }
        serialNumber
      }
    }
  `;
}
