import { DocumentNode, gql } from '@apollo/client';

export function getLanguagesByCountryQuery(countryId: string): DocumentNode {
  return gql`
    query {
      languagesByCountry(countryId: "${countryId}") {
        id,
        name,
        languageCode,
      }
    }
  `;
}
