import { gql } from '@apollo/client';

export const getAllLanguagesQuery = gql`
{
  languages {
    id,
    name,
    languageCode,
  }
}
`;
