import React, { ChangeEvent, DragEvent } from 'react';
import { ToastContainer } from 'react-toastify';

import { Config } from '../../../components/DialogRenderer';
import { HeaderComponent } from '../../../components/HeaderComponent';

import '../../../config/main.css';
import './CreateManualLabelDialog.css';

import { CreateManualLabelDialogState } from './contracts';
import { DocumentApolloClient } from '../../../api';
import { SaveDocumentRequest } from '../../../contracts';
import { ValidationError } from '../../../components/ValidationError/ValidationError';
import { ErrorHandler } from '../../../util';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';

export class CreateManualLabelDialog extends React.Component<CustomFormProps, CreateManualLabelDialogState> {

  private documentApolloClient: DocumentApolloClient;

  private _hasFiles = ({ dataTransfer: { types = [] as any } }): boolean => types.indexOf('Files') > -1;

  private counter = 0;

  private maxFileSize = 1024 * 1024 * 5;

  private formIsValid = (): boolean | undefined => this.state.formData.trackingId !== '' && this.state.files.length > 0;

  constructor(props: CustomFormProps) {
    super(props);

    this.documentApolloClient = new DocumentApolloClient(props.identity.token, props.config as Config);

    this.state = {
      files: [],
      formData: {
        trackingId: '',
      },
    };
  }

  public render(): JSX.Element {
    return (
      <>
        <HeaderComponent config={this.props.config} />
        <div className='Body'>

          <div className='container mx-auto max-w-screen-lg h-full'>
            <div className='flex flex-col w-[1000px]'>
              <h2 className='Body__Text-Headline font-bold mt-8'> <>Create Manual Label</> </h2>
              <p className='Body__Text-Part1 mt-8'> <>Please create a manual UPS label and use the upload service below to send the label combined with the packaging instructions to the customer.<br /> Please only upload the UPS label itself and (if applicable) the necessary customs documents, the packaging instructions will be attached to it automatically.</> </p>

              <h2 className='Body__Text-Headline font-bold mt-8'><>Customer Data</></h2>

              <div className='row'>
                <div className='col-sm-2'>First Name:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.firstName}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Last Name:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.lastName}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Email:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.email}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Phone:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.phone}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Address:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.address}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>PostalCode:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.postalCode}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>City:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.city}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Province:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.province}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Country:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.country.name}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Product Category:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.productCategory.name}</></div>
              </div>
              <div className='row'>
                <div className='col-sm-2'>Manufacturer:</div>
                <div className='col-sm-10'><> {this.props.userTask.tokens[0].payload.manufacturer}</></div>
              </div>
            </div>
          </div>

          <div className='Body__User-Input'>
            <main className='container mx-auto max-w-screen-lg h-full'>
              <article className='relative h-full flex flex-col bg-white shadow-xl rounded-md'
                aria-label='File Upload Modal'
                onDrop={this._dropHandler.bind(this)}
                onDragOver={this._dragOverHandler.bind(this)}
                onDragLeave={this._dragLeaveHandler.bind(this)}
                onDragEnter={this._dragEnterHandler.bind(this)}
              >
                <div id='overlay' className='w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md'>
                  <i>
                    <svg className='fill-current w-12 h-12 mb-3 text-blue-700' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z' />
                    </svg>
                  </i>
                  <p className='text-lg text-blue-700'>Drop files to upload</p>
                </div>

                <section className='h-full overflow-auto p-8 w-full h-full flex flex-col'>
                  <header className='border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center'>
                    <p className='mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
                      <span>Drag and drop your</span>&nbsp;<span>files anywhere or</span>
                    </p>
                    <input className='hidden'
                      id='hidden-input'
                      type='file'
                      multiple
                      accept='.pdf'
                      onChange={(event) => this._inputHandler(event)}
                    />
                    <button className='mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none'
                      id='button'
                      onClick={this._clickInput.bind(this)}
                    >
                      Browse files to upload
                    </button>
                    <p className='mb-3 pt-2 text-s text-gray-900 flex flex-wrap justify-center'>
                      <span>(maximum upload file size: 5 MB.)</span>
                    </p>
                  </header>

                  <h2 className='pt-8 pb-3 font-semibold sm:text-lg text-gray-900'>
                    Files selected for upload
                  </h2>

                  <ul className='flex flex-1 flex-wrap -m-1'
                    id='gallery'
                  >
                    {this.state.files.map((file) => (
                      <li className='block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24'
                        key={URL.createObjectURL(file)}
                      >
                        <article className='group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm'>
                          <img alt='upload preview' className='img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed' />

                          <section className='flex flex-col rounded-md text-xs break-words w-full h-full absolute top-0 py-2 px-3'>
                            <h2 className='flex-1 group-hover:text-blue-800'>{file.name}</h2>
                            <div className='flex'>
                              <span className='p-1 text-blue-800'>
                                <i>
                                  <svg className='fill-current w-4 h-4 ml-auto pt-1' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                    <path d='M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z' />
                                  </svg>
                                </i>
                              </span>
                              <p className='p-1 size text-xs text-gray-700'>
                                {file.size > 1024
                                  ? file.size > 1048576
                                    ? `${Math.round(file.size / 1048576)}mb`
                                    : `${Math.round(file.size / 1024)}kb`
                                  : `${file.size}b`}
                              </p>
                              <button className='delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800'
                                onClick={() => this._deleteFile(file.name)}
                              >
                                <svg className='pointer-events-none fill-current w-4 h-4 ml-auto' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                  <path className='pointer-events-none' d='M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z' />
                                </svg>
                              </button>
                            </div>
                          </section>
                        </article>
                      </li>
                    ),
                    )}
                    {this.state.files.length === 0 &&
                      <li id='empty' className='h-full w-full text-center flex flex-col items-center justify-center items-center'>
                        <img className='mx-auto w-32' src='https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png' alt='no data' />
                        <span className='text-small text-gray-500'>No files selected</span>
                      </li>
                    }
                  </ul>

                  <div className='flex-1 pt-6'>
                    <label className='block mt-2 mb-2 text-sm font-bold text-gray-900'>
                      UPS Tracking ID <label className='text-xs text-gray-500'>(required)</label>
                    </label>
                    <input id='trackingIdInput'
                      className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                      type='text'
                      onChange={e => this._updateFormDataHandler(e.target.value, 'trackingId')}
                      value={this.state.formData.trackingId ?? ''}
                    />
                    <div style={{ visibility: this.state.formData.trackingId !== '' ? 'hidden' : 'visible' }}>
                      <ValidationError errorMessage={'UPS Transaction Id is required'} />
                    </div>
                  </div>
                </section>


                <footer className='flex justify-end px-8 pb-8 pt-4'>
                  <button
                    id='submit'
                    className='disabled:bg-gray-700 disabled:pointer-events-none rounded-sm px-3 py-1 bg-blue-700 hover:bg-blue-500 text-white focus:shadow-outline focus:outline-none'
                    onClick={this._submitForm.bind(this)}
                    disabled={this.formIsValid() === false}
                  >
                    Upload now & send files to the customer
                  </button>
                </footer>
              </article>
            </main>
          </div>
        </div>
        <ToastContainer position='bottom-right' />
      </>
    );
  }

  private async _submitForm(): Promise<void> {
    document.body.style.cursor = 'progress';
    try {
      await this._uploadFiles();
    } catch (error) {
      ErrorHandler.handleError(error);

      return;
    } finally {
      document.body.style.cursor = 'default';
    }

    const payload = {
      manualFormData: this.state.formData,
    };

    this.props.finishUserTask(payload);
  }

  private _addFile(fileList: FileList | null): void {
    if (fileList !== null && fileList.length > 0) {
      let files: Array<File> = Array.from(fileList);
      files = files.filter((file) => file.type === 'application/pdf');
      files = files.filter((file) => !this.state.files.some((f) => f.name === file.name));
      files = files.filter((file) => file.size <= this.maxFileSize);

      this.setState({
        files: [...this.state.files, ...files],
      });
    }
  }

  private _inputHandler(event: ChangeEvent<HTMLInputElement> | null): void {
    if (event !== null) {

      const fileList: FileList | null = event?.target.files;
      this._addFile(fileList);

      event.target.value = '';
    }
  }

  private _dropHandler(event: DragEvent): void {
    event.preventDefault();

    const fileList: FileList | null = event.dataTransfer.files;
    this._addFile(fileList);

    const overlay: HTMLElement = document.getElementById('overlay') as HTMLElement;
    overlay.classList.remove('draggedover');

    this.counter = 0;
  }

  private _dragEnterHandler(event: DragEvent): void {
    event.preventDefault();

    if (!this._hasFiles(event)) {
      return;
    }

    this.counter++;

    const overlay: HTMLElement = document.getElementById('overlay') as HTMLElement;
    overlay.classList.add('draggedover');
  }

  private _dragLeaveHandler(event: DragEvent): void {
    this.counter--;

    if (this.counter === 0) {
      const overlay: HTMLElement = document.getElementById('overlay') as HTMLElement;
      overlay.classList.remove('draggedover');
    }
  }

  private _dragOverHandler(event: DragEvent): void {
    if (this._hasFiles(event)) {
      event.preventDefault();
    }
  }

  private _clickInput(): void {
    const input: HTMLElement = document.getElementById('hidden-input') as HTMLElement;
    input.click();
  }

  private _deleteFile(name: string): void {
    const files = this.state.files.filter((file: File) => file.name !== name);
    this.setState({
      files,
    });
  }

  private _fileToByteArray(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: Event) => {
        const byteArray = new Uint8Array(reader.result as ArrayBuffer);
        resolve(byteArray);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private async _uploadFiles(): Promise<void> {
    for (const file of this.state.files) {
      const byteArray = await this._fileToByteArray(file);

      const saveDocumentRequest: SaveDocumentRequest = {
        correlationId: this.props.userTask.correlationId,
        trackingId: this.state.formData.trackingId,
        data: byteArray,
      };


      const documentId = await this.documentApolloClient.saveDocument(saveDocumentRequest);
      const invalidPdf = documentId === null;
      if (invalidPdf) {
        throw new Error(`The PDF file ${file.name} is invalid, please check the file and try again.`);
      }

      this.setState({
        files: this.state.files.filter((f) => f.name !== file.name),
      });
    }
  }

  private _updateFormDataHandler(value: string, key: string): void {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
  }
}
