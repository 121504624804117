import { DocumentNode, gql } from '@apollo/client';
import { CreateRecyclingProcessRequest } from '../../../contracts';

export function createRecyclingProcessMutation(createRecyclingProcessRequest: CreateRecyclingProcessRequest): DocumentNode {
  return gql`
    mutation {
      createRecyclingProcess(input: {
        correlationId: "${createRecyclingProcessRequest.correlationId}"
        processStart: "${createRecyclingProcessRequest.processStart}"
        chosenProductCategoryId: "${createRecyclingProcessRequest.chosenProductCategoryId}"
        chosenManufacturer: "${createRecyclingProcessRequest.chosenManufacturer}"
        countryId: "${createRecyclingProcessRequest.countryId}"
        trackingId: "${createRecyclingProcessRequest.trackingId}"
        dateOfArrival: "${createRecyclingProcessRequest.dateOfArrival}"
        arrivedProductCategoryId: "${createRecyclingProcessRequest.arrivedProductCategoryId}"
        serialNumber: "${createRecyclingProcessRequest.serialNumber}"
      })
      {
        recyclingProcess {
          id
          correlationId
          processStart
          chosenProductCategoryId
          chosenManufacturer
          countryId
          trackingId
          dateOfArrival
          arrivedProductCategoryId
          serialNumber
        }
      }
    }
  `;
}
