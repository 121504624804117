import React, { FormEvent } from 'react';
import { toast } from 'react-toastify';

import { LogisticType } from '../../../contracts';
import { CountryComponentProps, CountryComponentState } from './contracts';

export class CountryComponent extends React.Component<CountryComponentProps, CountryComponentState> {

  constructor(props: any) {
    super(props);

    this.state = {
      countries: null,
      logisticTypes: [{ id: '', name: '' }],
      updateCountryRequest: {
        id: '',
        countryCode: '',
        name: '',
        logisticTypeId: '',
        localPartnerEmail: '',
        tesShipperNumber: '',
        recipientId: '',
      },
    };

    this.onCountryChange = this.onCountryChange.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const allLogisticTypes = await this.props.logisticTypeApolloClient.getAllLogisticTypes();

    this.setState({
      logisticTypes: allLogisticTypes,
    });
    await this.getAllCountries();
  }

  public render(): JSX.Element {

    if (this.state.countries !== null) {
      return (
        <div className='CountryTab'>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Country Code</th>
                <th>LogisticType</th>
                <th>TES Shipper Number</th>
                <th>Recipient-ID</th>
              </tr>
            </thead>
            {this.state.countries.map((country) => (
              <tbody key={country.id}>
                <tr onChange={this.onCountryChange}>
                  <td>{country.id}</td>
                  <td>{country.name}</td>
                  <td>{country.countryCode}</td>
                  <td>
                    <select
                      defaultValue={JSON.stringify(country.logisticType)}
                    >
                      {
                        this.state.logisticTypes.map((logisticType) => (
                          <option key={logisticType.id} value={JSON.stringify(logisticType)}>
                            {logisticType.name}
                          </option>
                        ))
                      }
                    </select>
                  </td>
                  <td>{country.tesShipperNumber}</td>
                  <td>{country.recipient.id}</td>
                </tr>
              </tbody>
            ))
            }
          </table>
        </div>
      );
    } else {
      return <></>;
    }
  }

  private async getAllCountries(): Promise<void> {
    const allCountries = await this.props.countryApolloClient.getAllCountries();
    allCountries.sort((a, b) => a.name < b.name ? -1 : 1);
    this.setState({
      countries: allCountries,
    });
  }

  private async onCountryChange(e: FormEvent<HTMLTableRowElement>): Promise<void> {
    const row: HTMLCollection = e.currentTarget.children;
    this.setState({
      updateCountryRequest: {
        id: row[0].textContent ?? '',
        name: row[1].textContent ?? '',
        countryCode: row[2].textContent ?? '',
        logisticTypeId: (JSON.parse(((row[3].children)[0] as HTMLInputElement).value) as LogisticType).id ?? '',
        localPartnerEmail: '',
        tesShipperNumber: row[4].textContent ?? '',
        recipientId: row[5].textContent ?? '',
      },
    }, this.updateCountry);

    await this.getAllCountries();
  }

  private async updateCountry(): Promise<void> {
    const updatedCountry = await this.props.countryApolloClient.updateCountry(this.state.updateCountryRequest);

    const countryUpdateSuccessful = updatedCountry !== null;
    if (countryUpdateSuccessful) {
      toast(`${this.state.updateCountryRequest.name} change successful`, { type: 'success' });
    }
  }
}
