import { DocumentNode, gql } from '@apollo/client';
import { UpdateCountryRequest } from '../../../contracts';

export function updateCountryMutation(updateCountryRequest: UpdateCountryRequest): DocumentNode {
  return gql`
    mutation {
      updateCountry(input: {
        id: "${updateCountryRequest.id}"
        name: "${updateCountryRequest.name}"
        countryCode: "${updateCountryRequest.countryCode.toUpperCase()}"
        logisticTypeId: "${updateCountryRequest.logisticTypeId}"
        localPartnerEmail: "${updateCountryRequest.localPartnerEmail}"
        tesShipperNumber: "${updateCountryRequest.tesShipperNumber}"
        recipientId: "${updateCountryRequest.recipientId}"
      })
      {
        country {
          id,
          name,
          countryCode,
          logisticTypeId,
          localPartnerEmail,
          tesShipperNumber,
          recipientId,
        }
      }
    }
  `;
}
