import { DocumentNode, gql } from '@apollo/client';
import { UpdateRecyclingProcessRequest } from '../../../contracts';

export function updateRecyclingProcessMutation(updateRecyclingProcessRequest: UpdateRecyclingProcessRequest): DocumentNode {
  return gql`
    mutation {
      updateRecyclingProcess(input: {
        id: "${updateRecyclingProcessRequest.id}"
        correlationId: "${updateRecyclingProcessRequest.correlationId}"
        processStart: "${updateRecyclingProcessRequest.processStart}"
        chosenProductCategoryId: "${updateRecyclingProcessRequest.chosenProductCategoryId}"
        chosenManufacturer: "${updateRecyclingProcessRequest.chosenManufacturer}"
        countryId: "${updateRecyclingProcessRequest.countryId}"
        trackingId: "${updateRecyclingProcessRequest.trackingId}"
        dateOfArrival: "${updateRecyclingProcessRequest.dateOfArrival}"
        arrivedProductCategoryId: "${updateRecyclingProcessRequest.arrivedProductCategoryId}"
        arrivedProductCategoryOther: "${updateRecyclingProcessRequest.arrivedProductCategoryOther}"
        serialNumber: "${updateRecyclingProcessRequest.serialNumber}"
      })
      {
        recyclingProcess {
          id
          correlationId
          processStart
          chosenProductCategoryId
          chosenManufacturer
          countryId
          trackingId
          dateOfArrival
          arrivedProductCategoryId
          arrivedProductCategoryOther
          serialNumber
        }
      }
    }
  `;
}
