import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Config } from '../../components';
import { CreateRecyclingProcessRequest, RecyclingProcess, UpdateRecyclingProcessRequest } from '../../contracts';
import { ErrorHandler } from '../../util';
import { createRecyclingProcessMutation, updateRecyclingProcessMutation } from './Mutations';
import { getRecyclingProcessByCorrelationIdQuery, getRecyclingProcessCountQuery, getRecyclingProcessesByFilterQuery } from './Queries';

export class RecyclingProcessApolloClient {

  private apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(identityToken: string, config: Config) {
    const httpLink = createHttpLink({
      uri: config.webAPIConfig.graphQlHttpApiUrl,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: identityToken ? `Bearer ${identityToken}` : '',
      },
    }));

    this.apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  public async getRecyclingProcessByCorrelationId(correlationId: string): Promise<RecyclingProcess | null> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getRecyclingProcessByCorrelationIdQuery(correlationId),
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.recyclingProcessByCorrelationId;
    } catch (error) {
      ErrorHandler.handleError(error);
      return null;
    }
  }

  public async getRecyclingProcessCount(startDate: string, endDate: string, hideInactiveProcesses: boolean, searchInput: string): Promise<number | null> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getRecyclingProcessCountQuery(startDate, endDate, hideInactiveProcesses, searchInput),
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.recyclingProcessCount;
    } catch (error) {
      ErrorHandler.handleError(error);
      return null;
    }
  }

  public async getRecyclingProcessesByFilter(startDate: string, endDate: string, hideInactiveProcesses: boolean, searchInput: string, position: number, take: number): Promise<Array<RecyclingProcess>> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getRecyclingProcessesByFilterQuery(startDate, endDate, hideInactiveProcesses, searchInput, position, take),
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.recyclingProcessesByFilter;
    } catch (error) {
      console.log(error);
      ErrorHandler.handleError(error);
      return [];
    }
  }

  public async createRecyclingProcess(createRecyclingProcessRequest: CreateRecyclingProcessRequest): Promise<RecyclingProcess | null> {
    try {
      const mutation = createRecyclingProcessMutation(createRecyclingProcessRequest);

      const mutationResult = await this.apolloClient.mutate({
        mutation: mutation,
        errorPolicy: 'all',
      });

      const recyclingProcess: RecyclingProcess = {
        id: mutationResult.data.createRecyclingProcess.recyclingProcess.id,
        correlationId: mutationResult.data.createRecyclingProcess.recyclingProcess.correlationId,
        processStart: mutationResult.data.createRecyclingProcess.recyclingProcess.processStart,
        chosenProductCategory: mutationResult.data.createRecyclingProcess.recyclingProcess.chosenProductCategory,
        chosenManufacturer: mutationResult.data.createRecyclingProcess.recyclingProcess.chosenManufacturer,
        country: mutationResult.data.createRecyclingProcess.recyclingProcess.country,
        trackingId: mutationResult.data.createRecyclingProcess.recyclingProcess.trackingId,
        dateOfArrival: mutationResult.data.createRecyclingProcess.recyclingProcess.dateOfArrival,
        dateOfCancellation: mutationResult.data.createRecyclingProcess.recyclingProcess.dateOfCancellation,
        serialNumber: mutationResult.data.createRecyclingProcess.recyclingProcess.serialNumber,
      };

      return recyclingProcess;
    } catch (error) {
      ErrorHandler.handleError(error);
      return null;
    }
  }

  public async updateRecyclingProcess(updateRecyclingProcessRequest: UpdateRecyclingProcessRequest): Promise<RecyclingProcess | null> {
    try {
      const mutation = updateRecyclingProcessMutation(updateRecyclingProcessRequest);

      const mutationResult = await this.apolloClient.mutate({
        mutation: mutation,
        errorPolicy: 'all',
      });

      const recyclingProcess: RecyclingProcess = {
        id: mutationResult.data.updateRecyclingProcess.recyclingProcess.id,
        correlationId: mutationResult.data.updateRecyclingProcess.recyclingProcess.correlationId,
        processStart: mutationResult.data.updateRecyclingProcess.recyclingProcess.processStart,
        chosenProductCategory: mutationResult.data.updateRecyclingProcess.recyclingProcess.chosenProductCategory,
        chosenManufacturer: mutationResult.data.updateRecyclingProcess.recyclingProcess.chosenManufacturer,
        country: mutationResult.data.updateRecyclingProcess.recyclingProcess.country,
        trackingId: mutationResult.data.updateRecyclingProcess.recyclingProcess.trackingId,
        dateOfArrival: mutationResult.data.updateRecyclingProcess.recyclingProcess.dateOfArrival,
        dateOfCancellation: mutationResult.data.updateRecyclingProcess.recyclingProcess.dateOfCancellation,
        arrivedProductCategory: mutationResult.data.updateRecyclingProcess.recyclingProcess.arrivedProductCategory,
        arrivedProductCategoryOther: mutationResult.data.updateRecyclingProcess.recyclingProcess.arrivedProductCategoryOther,
        serialNumber: mutationResult.data.updateRecyclingProcess.recyclingProcess.serialNumber,
      };

      return recyclingProcess;

    } catch (error) {
      ErrorHandler.handleError(error);
      return null;
    }
  }

}
