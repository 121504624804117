import React from 'react';

import { LogisticTypeComponentProps, LogisticTypeComponentState } from './contracts';

export class LogisticTypeComponent extends React.Component<LogisticTypeComponentProps, LogisticTypeComponentState> {

  constructor(props: any) {
    super(props);

    this.state = {
      logisticTypes: null,
    };

  }

  public async componentDidMount(): Promise<void> {
    const allLogisticTypes = await this.props.logisticTypeApolloClient.getAllLogisticTypes();

    this.setState({
      logisticTypes: allLogisticTypes,
    });

  }

  public render(): JSX.Element {
    if (this.state.logisticTypes !== null) {
      return (
        <div className='LogisticTypeTab'>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            {this.state.logisticTypes.map((logisticType) => (
              <tbody key={logisticType.id}>
                <tr>
                  <td>{logisticType.id}</td>
                  <td>{logisticType.name}</td>
                </tr>
              </tbody>
            ))
            }
          </table>
        </div>
      );
    } else {
      return <></>;
    }
  }
}
