import React, { ChangeEvent } from 'react';

import { ProductCategoryApolloClient } from '../../../api';
import { Config } from '../../../components';
import { HeaderComponent } from '../../../components/HeaderComponent';
import './IncomingPackageCustomForm.css';
import { IncomingPackageCustomState } from './contracts';
import i18n from '../../../util/i18n';
import { RecyclingProcessApolloClient } from '../../../api/recyclingProcess';
import { ProductCategory, RecyclingProcess } from '../../../contracts';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';

export class IncomingPackageCustomForm extends React.Component<CustomFormProps, IncomingPackageCustomState> {

  private productCategoryApolloClient: ProductCategoryApolloClient;
  private recyclingProcessApolloClient: RecyclingProcessApolloClient;

  constructor(props: CustomFormProps) {
    super(props);

    this.state = {
      allProductCategories: [{ id: '', name: '', packageWeight: '', isOther: false, commodityCode: '', invoiceValue: '' }],
      currentProcess: null,
      formData: {
        incomingProductCategory: { id: '', name: '', packageWeight: '', isOther: false, commodityCode: '', invoiceValue: '' },
        incomingProductCategoryOther: '',
        incomingSerialNumber: '',
      },
    };

    this.productCategoryApolloClient = new ProductCategoryApolloClient(props.identity.token, props.config as Config);
    this.recyclingProcessApolloClient = new RecyclingProcessApolloClient(props.identity.token, props.config as Config);

    this._handleProductCategoryChange = this._handleProductCategoryChange.bind(this);
    this._handleProductCategoryOtherChange = this._handleProductCategoryOtherChange.bind(this);
    this._handleSerialNumberChange = this._handleSerialNumberChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const allProductCategories: Array<ProductCategory> = await this.productCategoryApolloClient.getAllProductCategories();
    const defaultProduct: ProductCategory | undefined = allProductCategories.find(productCategory => productCategory.name === 'Smartphones');
    const currentProcess: RecyclingProcess | null = await this.recyclingProcessApolloClient.getRecyclingProcessByCorrelationId(this.props.userTask.correlationId);

    if (!allProductCategories || !defaultProduct || !currentProcess)
      return;

    this.setState(prevState => ({
      allProductCategories: allProductCategories,
      currentProcess: currentProcess,
      formData: {
        ...prevState.formData,
        incomingProductCategory: defaultProduct,
      },
    }));
  }

  public render(): JSX.Element {
    let productCategoryOther: JSX.Element = <></>;

    if (this.state.formData.incomingProductCategory.isOther) {
      productCategoryOther = (
        <div className='form__device-other flex flex-col'>
          <label className='mb-2 text-sm font-bold text-gray-900'> <>Other product category</> </label>
          <input className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
            type='text'
            onChange={this._handleProductCategoryOtherChange}
            value={this.state.formData.incomingProductCategoryOther}
          />
        </div>
      );
    }

    return (
      <>
        <HeaderComponent config={this.props.config} />
        <div className='body__form flex flex-col justify-center gap-4 w-[600px] mx-auto'>
          <h2 className='form__heading font-bold mt-8'>Register incoming goods</h2>
          <p className='Body__Text-Part1'> <>Please complete the form below to complete the goods receival process for this consumer return process.</> </p>

          <p>Process: {this.props.userTask.correlationId}</p>

          <div className='form__device-selection flex flex-col'>
            <label className='mb-2 text-sm font-bold text-gray-900'> <>Product category of the received device</> </label>
            <select name='DeviceSeletor'
              id='DeviceSeletor'
              value={JSON.stringify(this.state.formData.incomingProductCategory)}
              className='p-2 text-center bg-gray-50 rounded-xl border-1'
              onChange={this._handleProductCategoryChange}
            >
              {
                this.state.allProductCategories.map((productCategory) => (
                  <option key={productCategory.name} value={JSON.stringify(productCategory)}>
                    {productCategory.name}
                  </option>
                ))
              }
            </select>
          </div>
          {productCategoryOther}
          <div className='form__phone flex-1'>
            <label className='mb-2 text-sm font-bold text-gray-900'> <>Serialnumber of the device</> </label>
            <input className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
              type='text'
              onChange={this._handleSerialNumberChange}
              value={this.state.formData.incomingSerialNumber}
            />
          </div>
          <div className='form__submit-button justify-end flex items-center mt-4'>
            <button className='bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded-xl'
              onClick={this._handleSubmit}
            >
              <>{i18n.t('Submit')}</>
            </button>
          </div>
        </div>
      </>
    );
  }

  private _handleProductCategoryChange(e: ChangeEvent): void {
    const productCategory = JSON.parse((e.target as HTMLInputElement).value);

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        incomingProductCategory: productCategory,
      },
    }));
  }

  private _handleProductCategoryOtherChange(e: ChangeEvent): void {
    const productCategoryOther = (e.target as HTMLInputElement).value;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        incomingProductCategoryOther: productCategoryOther,
      },
    }));
  }

  private _handleSerialNumberChange(e: ChangeEvent): void {
    const serialNumber = (e.target as HTMLInputElement).value;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        incomingSerialNumber: serialNumber,
      },
    }));
  }

  private async _handleSubmit(): Promise<void> {
    const payload = this.state.formData;

    if (this.state.currentProcess) {
      await this.recyclingProcessApolloClient.updateRecyclingProcess({
        ...this.state.currentProcess,
        chosenProductCategoryId: this.state.currentProcess.chosenProductCategory.id,
        countryId: this.state.currentProcess.country.id,
        dateOfArrival: new Date().toISOString(),
        serialNumber: this.state.formData.incomingSerialNumber,
        arrivedProductCategoryId: this.state.formData.incomingProductCategory.id,
        arrivedProductCategoryOther: this.state.formData.incomingProductCategoryOther,
      });
    }

    const customerSupportPortalUrl = (this.props.config as Config).CustomerSupportPortalUrl;
    if (window !== null && window.top !== null && customerSupportPortalUrl !== null) {
      window.top.location = customerSupportPortalUrl;
    }

    this.props.finishUserTask(payload);
  }
}
