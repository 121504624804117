import { gql } from '@apollo/client';

export const getAllCountriesQuery = gql`
{
  countries {
    id,
    name,
    countryCode,
    logisticType {
      id,
      name,
    },
    localPartnerEmail,
    tesShipperNumber,
    recipient {
      id,
      name,
      city {
      id,
      postalCode,
      name,
      }
      street,
      houseNumber,
      phoneNumber,
      countryCode,
    },
  }
}
`;
