import { DocumentNode, gql } from '@apollo/client';

export const getLanguagePreferLevelsByCountryIdQuery = (countryId: string): DocumentNode => gql`
  query {
    languagePreferLevelsByCountryId(id: "${countryId}") {
      countryId,
      languageId,
      preferLevel,
    }
  }
`;
