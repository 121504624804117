import React, { MouseEvent } from 'react';
import { PaginatorProps, PaginatorState } from './contracts';

export class Paginator extends React.Component<PaginatorProps, PaginatorState> {

  constructor(props: PaginatorProps) {
    super(props);

    this.state = {
      pages: 0,
      entriesPerPage: 10,
      pagesPerView: 5,
      entriesPerView: 50,
    };

    this._handleBackwardClick = this._handleBackwardClick.bind(this);
    this._handleForwardClick = this._handleForwardClick.bind(this);
    this._onClick = this._onClick.bind(this);
  }

  public componentDidMount(): void {
    this.setState({
      pages: Math.ceil((this.props.entries ?? 0) / (this.props.entriesPerPage ?? 10)),
      entriesPerPage: this.props.entriesPerPage ?? 10,
      pagesPerView: this.props.pagesPerView ?? 5,
      entriesPerView: (this.props.entriesPerPage ?? 10) * (this.props.pagesPerView ?? 5),
    });
  }

  componentDidUpdate(prevProps: Readonly<PaginatorProps>, prevState: Readonly<PaginatorState>, snapshot?: any): void {
    (prevProps.entries !== this.props.entries) && this.setState({
      pages: Math.ceil((this.props.entries ?? 0) / this.state.entriesPerPage),
      entriesPerPage: this.props.entriesPerPage ?? 10,
      pagesPerView: this.props.pagesPerView ?? 5,
      entriesPerView: (this.props.entriesPerPage ?? 10) * (this.props.pagesPerView ?? 5),
    });
  }

  public _handleBackwardClick(e: MouseEvent<HTMLElement>): void {
    if (!this.props.position) {
      return;
    }
    const currentPage = ((this.props.position ?? 0) / this.state.entriesPerPage);

    const pageInView = (currentPage % this.state.pagesPerView);

    let newPosition = this.props.position;
    if (this.props.position % this.state.entriesPerView === 0) {
      newPosition -= this.state.entriesPerView;
    } else {
      newPosition = this.props.position - ((pageInView) * this.state.entriesPerPage) - this.state.entriesPerView;
    }

    this.props.onBackwardClick(newPosition);
  }

  public _handleForwardClick(e: MouseEvent<HTMLElement>): void {

    const currentPage = ((this.props.position ?? 0) / this.state.entriesPerPage);

    const pageInView = currentPage % this.state.pagesPerView;

    let newPosition = (this.props.position ?? 0);
    if ((this.props.position ?? 0) % this.state.entriesPerView === 0) {
      newPosition += this.state.entriesPerView;
    } else {
      newPosition = (this.props.position ?? 0) - ((pageInView) * this.state.entriesPerPage) + this.state.entriesPerView;
    }

    this.props.onForwardClick(newPosition);
  }

  public _onClick(e: MouseEvent<HTMLElement>): void {
    const page = parseInt((e.target as HTMLElement).innerText);

    this.props.onClick((page - 1) * this.state.entriesPerPage);
  }

  public _generatePages(): JSX.Element {
    const pages: Array<JSX.Element> = [];

    if (this.props.entries == null || this.props.position == null) {
      return <></>;
    }

    let index = 0;

    const currentPage = ((this.props.position ?? 0) / this.state.entriesPerPage);

    const upperLimit = this.state.pagesPerView - (currentPage % this.state.pagesPerView) + currentPage;

    if (currentPage % this.state.pagesPerView === 0) {
      index = currentPage;
    } else {
      index = currentPage - currentPage % this.state.pagesPerView;
    }

    while (index < this.state.pages && index < upperLimit) {
      const isSelected = currentPage === index;

      const page = (
        <button
          key={index}
          onClick={this._onClick}
          className={isSelected ? 'text-blue-500 relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
        >
          {index + 1}
        </button>
      );

      pages.push(page);
      index++;
    }

    return <>{pages}</>;
  }

  public render(): JSX.Element {

    const currentPage = ((this.props.position ?? 0) / this.state.entriesPerPage);

    return (
      <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'>
        <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
          <div>
            <p className='text-sm text-gray-700'>
              Showing <span className='font-medium'>{this.props.position}</span> to <span className='font-medium'>{(this.props.position ?? 0) + (this.props.entriesPerPage ?? 0)}</span> {'of '}
              <span className='font-medium'>{this.props.entries}</span> results
            </p>
          </div>
          <div>
            <nav className='inline-flex -space-x-px rounded-md shadow-sm' aria-label='Pagination'>
              {currentPage - this.state.pagesPerView < 0 ? '' : <button className='relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' onClick={this._handleBackwardClick}>Back</button>}
              {this._generatePages()}
              {this.state.pagesPerView - (currentPage % this.state.pagesPerView) + currentPage < this.state.pages ? <button className='relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' onClick={this._handleForwardClick}>Next</button> : ''}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
