import { gql } from '@apollo/client';

export const getAllLogisticCompaniesQuery = gql`
{
  logisticCompanies {
    id,
    name,
  }
}
`;
