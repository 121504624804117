import { gql } from '@apollo/client';

export const getAllProductCategoriesQuery = gql`
{
  productCategories {
    id,
    name,
    packageWeight,
    isOther,
    commodityCode,
    invoiceValue,
  }
}
`;
