/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { DocumentApolloClient } from '../../../api';

import { Config } from '../../../components/DialogRenderer';
import { parseLanguageCountryCode } from '../../../util';
import i18n from '../../../util/i18n';
import { isRightToLeft } from '../../../util/isRightToLeft';
import { HeaderComponent } from '../../../components/HeaderComponent';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';

export class TesLocalCollectionEmailDeliveredCustomForm extends React.Component<CustomFormProps> {

  private documentApolloClient: DocumentApolloClient;

  constructor(props: CustomFormProps) {
    super(props);

    this.documentApolloClient = new DocumentApolloClient(props.identity.token, props.config as Config);

    const languageCode = this.props.userTask.tokens[0].payload.language.languageCode;
    const countryCode = this.props.userTask.tokens[0].payload.country.countryCode;

    i18n.changeLanguage(parseLanguageCountryCode(languageCode, countryCode)).catch(() => {
      console.log('Language couldn\'t be translated');
    });
  }

  public render(): JSX.Element {
    return (
      <div dir={isRightToLeft(this.props.userTask.tokens[0].payload.language.languageCode) ? 'ltr' : 'ltr'}>
        <HeaderComponent config={this.props.config} />
        <div className='flex flex-col mx-auto w-[600px]'>
          <h2 className='text-center font-bold my-4'>
            <>{i18n.t('MailDeliveredCustomForm_Header')}</>
          </h2>
          <p className='text-center'><>{i18n.t('MailDeliveredCustomForm_Subheader')}</></p>
          <p className='text-center font-bold my-4'>
            {this.props.userTask.correlationId}
          </p>
          <p className='Body__Headline-Text text-center mt-2'>
            <>{i18n.t('You will recieve an E-Mail from a local partner soon.')}</>
          </p>
        </div>
      </div>
    );
  }
}
