import React from 'react';


import { parseLanguageCountryCode } from '../../../util';
import i18n from '../../../util/i18n';
import { HeaderComponent } from '../../../components/HeaderComponent';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';

export class CustomerMailDeliveredCustomForm extends React.Component<CustomFormProps> {


  constructor(props: CustomFormProps) {
    super(props);

    const languageCode = this.props.userTask.tokens[0].payload.language.languageCode;
    const countryCode = this.props.userTask.tokens[0].payload.country.countryCode;

    i18n.changeLanguage(parseLanguageCountryCode(languageCode, countryCode)).catch(() => {
      console.log('Language couldn\'t be translated');
    });
  }
  public render(): JSX.Element {
    return (
      <div>
        <HeaderComponent config={this.props.config} />
        <div className='flex flex-col mx-auto w-[600px]'>
          <h2 className='text-center font-bold my-4'>
            Awaiting customer return
          </h2>
          <p className='text-center'><>The email containing the label & the packaging instructions has been sent to the customer. We are now awaiting the return of the customer's product(s).</></p>
        </div>
      </div>
    );
  }
}
