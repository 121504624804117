import { DocumentNode, gql } from '@apollo/client';

import { SaveDocumentRequest } from '../../../contracts/SaveDocumentRequest';

export function saveDocumentMutation(saveDocumentRequest: SaveDocumentRequest): DocumentNode {
  return gql`
    mutation {
      saveDocument(input: {
        correlationId: "${saveDocumentRequest.correlationId}"
        data: [${saveDocumentRequest.data}]
        trackingId: "${saveDocumentRequest.trackingId}"
      })
      {
        string
      }
    }
  `;
}
