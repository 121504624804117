/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { parseLanguageCountryCode } from '../../../util';
import i18n from '../../../util/i18n';
import { HeaderComponent } from '../../../components/HeaderComponent';
import { LogisticTypeName } from '../../../contracts';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';
import { isRightToLeft } from '../../../util/isRightToLeft';
import { Config } from '../../../components';

export class TesMailDeliveredCustomForm extends React.Component<CustomFormProps> {

  public state: { file: Blob } = { file: new Blob() };

  constructor(props: CustomFormProps) {
    super(props);

    const languageCode = this.props.userTask.tokens[0].payload.language.languageCode;
    const countryCode = this.props.userTask.tokens[0].payload.country.countryCode;

    i18n.changeLanguage(parseLanguageCountryCode(languageCode, countryCode)).catch(() => {
      console.log('Language couldn\'t be translated');
    });
  }

  public render(): JSX.Element {
    return (
      <div dir={isRightToLeft(this.props.userTask.tokens[0].payload.language.languageCode) ? 'ltr' : 'ltr'}>
        <HeaderComponent config={this.props.config} />
        <div className='flex flex-col mx-auto w-[600px]'>
          <h2 className='text-center font-bold my-4'>
            <>{i18n.t('SuccessPage_Headline')}</>
          </h2>
          <p><>{i18n.t('SuccessPage_Subtext1')}</></p>

          <p className='mt-4'><>{i18n.t('SuccessPage_ReferenceNumber_Headline1')}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_ReferenceNumber_Subtext1')}<br />{this.props.userTask.correlationId}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_ReferenceNumber_Subtext2')}</></p>

          <p className='mt-4'><>{i18n.t('SuccessPage_Packing_Headline1')}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_Packing_Subtext1')}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_Packing_Subtext2')}</></p>

          {this.isManualDoorstepCollection() &&
            <div>
              <p className='mt-4'><>{i18n.t('SuccessPage_Label_Doorstep_Headline1')}</></p>
              <p className='mt-2'><>{i18n.t('SuccessPage_Label_DoorstepManual_Subtext1')}</></p>
            </div>
          }
          {this.isManualLabel() &&
            <div>
              <p className='mt-4'><>{i18n.t('SuccessPage_Label_Headline1')}</></p>
              <p className='mt-2'><>{i18n.t('SuccessPage_Label_ManualSubtext1')}</></p>
            </div>
          }
          <div className='Body__Download-PDF h-12 flex items-center gap-4 mt-4'>
            <a id='downloadButton'
              onClick={this._downloadPdf.bind(this)}
              download='Instruction.pdf'
              className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl hover:cursor-pointer'
            >
              <>{i18n.t('Download ShippingDirections')}</>
            </a>
          </div>
        </div>
      </div>
    );
  }

  private _downloadPdf(): void {
    const currentCulture = `${this.props.userTask.tokens[0].payload.language.languageCode}-${this.props.userTask.tokens[0].payload.country.countryCode}`;
    const filename = `Shipping_Directions.${currentCulture}.pdf`;

    const url = `/content/pdf/${filename}`;
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  }

  private isManualLabel(): boolean {
    const logisticTypeName = this.props.userTask.tokens[0].payload.country.logisticType.name;

    return logisticTypeName === LogisticTypeName.manual;
  }

  private isManualDoorstepCollection(): boolean {
    const logisticTypeName = this.props.userTask.tokens[0].payload.country.logisticType.name;

    return logisticTypeName === LogisticTypeName.manual_doorstep;
  }
}
