import React from 'react';


import { ValidationErrorProps } from './ValidationErrorProps'; 
import '../../config/main.css';

class ValidationError extends React.Component<ValidationErrorProps> {

  public render(): JSX.Element {
    return (
      <p className='text-red-700 text-sm mt-2'>
        {this.props.errorMessage}
      </p>
    );  
  }
}

export { ValidationError };
