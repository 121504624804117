import React from 'react';
import { LogisticCompanyComponentProps, LogisticCompanyComponentState } from './contracts';

export class LogisticCompanyComponent extends React.Component<LogisticCompanyComponentProps, LogisticCompanyComponentState> {

  constructor(props: any) {
    super(props);

    this.state = {
      logisticCompanies: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    const allLogisticCompanies = await this.props.logisticCompanyApolloClient.getAllLogisticCompanies();

    this.setState({
      logisticCompanies: allLogisticCompanies,
    });
  }

  public render(): JSX.Element {
    if (this.state.logisticCompanies !== null) {
      return (
        <div className='LogisticCompanyTab'>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            {this.state.logisticCompanies.map((logisticCompany) => (
              <tbody key={logisticCompany.id}>
                <tr>
                  <td>{logisticCompany.id}</td>
                  <td>{logisticCompany.name}</td>
                </tr>
              </tbody>
            ))
            }
          </table>
        </div>
      );
    } else {
      return <></>;
    }
  }
}
