import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { Config } from '../../components';
import { Document, SaveDocumentRequest } from '../../contracts';
import { ErrorHandler } from '../../util';
import { saveDocumentMutation } from './Mutations';
import { getAllDocumentsByCorrelationIdQuery } from './Queries/GetAllDocumentsByCorrelationIdQuery';

export class DocumentApolloClient {

  private apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(identityToken: string, config: Config) {
    const httpLink = createHttpLink({
      uri: config.webAPIConfig.graphQlHttpApiUrl,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: identityToken ? `Bearer ${identityToken}` : '',
      },
    }));

    this.apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  public async saveDocument(saveDocumentRequest: SaveDocumentRequest): Promise<string | null> {
    const mutation = saveDocumentMutation(saveDocumentRequest);
    const mutationResult = await this.apolloClient.mutate({
      mutation: mutation,
      errorPolicy: 'all',
    });

    const documentId = mutationResult.data.saveDocument.string;

    return documentId;
  }

  public async getAllDocumentsByCorrelationId(correlationId: string): Promise<any> {
    try {
      const query = getAllDocumentsByCorrelationIdQuery(correlationId);
      const queryResult = await this.apolloClient.query({
        query: query,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const documents: Array<Document> = [];

      queryResult.data.allDocumentsByCorrelationId.forEach((document: Document) => {
        documents.push(document);
      });

      return documents;
    } catch (error) {
      ErrorHandler.handleError(error);
      return [];
    }
  }

}
