/* eslint-disable jsx-a11y/anchor-has-content */
import React, { ChangeEvent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  de,
  enGB,
  enUS,
  pt,
  pl,
  fr,
  tr,
  nl,
  it,
  ar,
  es,
  nlBE,
} from 'date-fns/locale';

import { HeaderComponent } from '../../components/HeaderComponent/HeaderComponent';
import '../../config/main.css';
import './RecyclingPlatformStartDialog.css';
import logoProducts from '../../assets/Images/MultiProducts.jpg';
import {
  Country,
  Language,
  Manufacturer,
  ProductCategory,
} from '../../contracts';
import { CountryApolloClient, LanguageApolloClient, ProductCategoryApolloClient } from '../../api';
import i18n from '../../util/i18n';
import { isRightToLeft } from '../../util/isRightToLeft';
import { RecyclingPlatformStartDialogState } from './contracts';
import { ValidationError } from '../../components/ValidationError/ValidationError';
import { Config } from '../../components';
import { StartDialogProps } from '../../components/DialogRenderer/StartDialogRenderer/contracts';
import { DoorstepCollectionErrorModal } from './DoorstepCollectionErrorModal/DoorstepCollectionErrorModal';
import { DoorstepCollectionError } from './DoorstepCollectionErrorModal/DoorstepCollectionError.enum';

export class RecyclingPlatformStartDialog extends React.Component<StartDialogProps, RecyclingPlatformStartDialogState> {

  private productCategoryApolloClient: ProductCategoryApolloClient;
  private countryApolloClient: CountryApolloClient;
  private languageApolloClient: LanguageApolloClient;

  private initialDoorstepCollectionTimes: { startTime: string, endTime: string } = { startTime: '', endTime: '' };

  private doorstepCollectionErrorType: DoorstepCollectionError | null = this._determineErrorType(this.props.payload?.UPSErrorCode);

  constructor(props: StartDialogProps) {
    super(props);

    this._registerLanguages();

    this.languageApolloClient = new LanguageApolloClient(props.identity.token, props.config as Config);
    this.productCategoryApolloClient = new ProductCategoryApolloClient(props.identity.token, props.config as Config);
    this.countryApolloClient = new CountryApolloClient(props.identity.token, props.config as Config);

    this._setinitialDoorstepCollectionTimes();

    const payload = props.payload;

    this.state = {
      allProductCategories: [{ id: '', name: '', packageWeight: '', isOther: false, commodityCode: '', invoiceValue: '' }],
      formData: {
        firstName: payload?.FirstName ?? null,
        lastName: payload?.LastName ?? null,
        email: payload?.Email ?? null,
        phone: payload?.Phone ?? null,
        address: payload?.AddressLine ?? null,
        address2: '',
        postalCode: payload?.PostalCode ?? null,
        city: payload?.City ?? null,
        province: payload?.StateProvince ?? null,
        productCategory: {
          id: '',
          name: '',
          packageWeight: '',
          isOther: false,
          commodityCode: '',
          invoiceValue: '',
        },
        deviceAmount: 1,
        manufacturer: Manufacturer.APPLE,
        country: {
          id: '',
          countryCode: '',
          name: '',
          logisticType: {
            id: '',
            name: '',
          },
          localPartnerEmail: '',
          tesShipperNumber: '',
          recipient: {
            id: '',
            name: '',
            city: {
              id: '',
              postalCode: '',
              name: '',
            },
            street: '',
            houseNumber: '',
            phoneNumber: '',
            countryCode: '',
          },
        },
        language: {
          id: '',
          languageCode: '',
          name: '',
        },
        startDateTime: this.initialDoorstepCollectionTimes.startTime,
        endDateTime: this.initialDoorstepCollectionTimes.endTime,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      isFormValid: false,
      isFirstnameValid: this._isNotEmpty(payload?.FirstName),
      isLastnameValid: this._isNotEmpty(payload?.LastName),
      isEmailValid: this._checkIsEmailValid(payload?.Email),
      isPhoneValid: this._checkIsPhoneValid(payload?.Phone),
      isAddressValid: this._isNotEmpty(payload?.AddressLine),
      isCityValid: this._isNotEmpty(payload?.City),
      isPostalCodeValid: this._isNotEmpty(payload?.PostalCode),
      isAppleTermsValid: false,
      isUPSTermsValid: false,
      isTesTermsValid: false,
      isDoorstepCollectionValid: true,
      isConsentValid: false,
      finishedLoading: false,
      isDoorstepCollection: false,
      showDoorstepCollectionErrorModal: payload?.UPSErrorCode >= 0,
      doorstepCollectionTimeslot: 1,
    };

    this._handleDoorstepCollectionDayChange = this._handleDoorstepCollectionDayChange.bind(this);
    this._handleProductCategoryChange = this._handleProductCategoryChange.bind(this);
    this._getCountryChange = this._getCountryChange.bind(this);
    this._getLanguageChange = this._getLanguageChange.bind(this);
    this._handleManufacturerChange = this._handleManufacturerChange.bind(this);
    this._finishedLoading = this._finishedLoading.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const allProductCategories: Array<ProductCategory> = await this.productCategoryApolloClient.getProductCategoriesForCustomerPortal(false);

    if (!allProductCategories) {
      return;
    }

    allProductCategories.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1);

    let productCategory: ProductCategory;
    if (this.props.payload) {
      const productcategoryId = this.props.payload.ProductCategory.Id;
      const payloadProduct = allProductCategories.find(productCategory => productCategory.id === productcategoryId);
      if (payloadProduct) {
        productCategory = payloadProduct;
      }
    } else {
      const defaultProduct: ProductCategory | undefined = allProductCategories.find(productCategory => productCategory.name === 'Smartphones');
      if (defaultProduct) {
        productCategory = defaultProduct;
      }
    }

    this.setState(prevState => ({
      allProductCategories: allProductCategories,
      formData: {
        ...prevState.formData,
        productCategory: productCategory,
      },
    }));
  }

  private _finishedLoading(finishedLoading: boolean): void {
    this.setState({
      finishedLoading: finishedLoading,
    });
  }

  private _getLanguageChange(language: Language): void {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        language: language,
      },
    }));
  }

  private _getCountryChange(country: Country): void {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        country: country,
      },
    }), () => this._setIsDoorStepCollection());
  }

  public render(): JSX.Element {
    const currentCulture = `${this.state.formData.language.languageCode}-${this.state.formData.country.countryCode}`;

    return (
      <div className='body' dir={isRightToLeft(this.state.formData.language.languageCode) ? 'rtl' : 'ltr'} style={{ visibility: this.state.finishedLoading ? 'visible' : 'hidden' }}>
        {this.state.showDoorstepCollectionErrorModal && <DoorstepCollectionErrorModal errorType={this.doorstepCollectionErrorType as DoorstepCollectionError} toggleModal={this._toggleDoorstepCollectionErrorModal.bind(this)} />}
        <HeaderComponent
          portalLocation={this.props.portalLocation}
          portalLanguageCode={this.props.language}
          getCountryChange={this._getCountryChange}
          getLanguageChange={this._getLanguageChange}
          countryApolloClient={this.countryApolloClient}
          languageApolloClient={this.languageApolloClient}
          finishedLoading={this._finishedLoading}
          config={this.props.config}
        />

        <div className='body__headline'>
          <h1 className='headline__heading text-center font-bold mt-8'> <>{i18n.t('Time to recycle your device.')}</> </h1>
          <p className='headline__text text-center mt-8'> <>{i18n.t('Complete the form below so you can easily send your device to be reused or recycled.')}</> </p>
          <p className='headline__text text-center '>
            <a href='#faq'><>{i18n.t('Frequently Asked Questions')}</></a> • <>{i18n.t('For more options visit')}</> <a href={`${i18n.t('LinkToAppleTradeIn')}`} target='_blank' rel='noreferrer'><>{i18n.t('Apple Trade In')}</></a>
          </p>
          <img src={logoProducts} alt='logo' loading='lazy' className='mx-auto scale-75' />
        </div>

        <div className='body__form flex flex-col justify-center gap-4 mx-auto w-[600px]'>
          <div className='form__name-container flex items-center gap-4'>
            <div className='form__firstname flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900'> <>{i18n.t('Firstname')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <input id='firstNameInput'
                className='w-full p-2  text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='text'
                onChange={e => this._updateFormDataHandler(e.target.value, 'firstName')}
                value={this.state.formData.firstName ?? ''}
              />
              <div style={{ visibility: this.state.isFirstnameValid || this.state.formData.firstName === null ? 'hidden' : 'visible' }}>
                <ValidationError errorMessage={i18n.t('Firstname is required')} />
              </div>
            </div>
            <div className='form__lastname flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900 '> <>{i18n.t('Lastname')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <input id='lastNameInput'
                className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='text'
                onChange={e => this._updateFormDataHandler(e.target.value, 'lastName')}
                value={this.state.formData.lastName ?? ''}
              />
              <div style={{ visibility: this.state.isLastnameValid || this.state.formData.lastName === null ? 'hidden' : 'visible' }}>
                <ValidationError errorMessage={i18n.t('Lastname is required')} />
              </div>
            </div>
          </div>
          <div className='form__contact-container flex items-center gap-4'>
            <div className='form__email flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900 '> <>{i18n.t('E-Mail')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <input id='emailInput'
                className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='email'
                onChange={e => this._updateFormDataHandler(e.target.value, 'email')}
                value={this.state.formData.email ?? ''}
              />
              <div style={{ visibility: this.state.isEmailValid || this.state.formData.email === null ? 'hidden' : 'visible' }}>
                <ValidationError errorMessage={i18n.t('Email not valid')} />
              </div>
            </div>
            <div className='form__phone flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900 '> <>{i18n.t('Phone')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <input id='phoneInput'
                className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='text'
                onChange={e => this._updateFormDataHandler(e.target.value, 'phone')}
                value={this.state.formData.phone ?? ''}
              />
              <div style={{ visibility: this.state.isPhoneValid || this.state.formData.phone === null ? 'hidden' : 'visible' }}>
                <ValidationError errorMessage={i18n.t('Phone is required')} />
              </div>
            </div>
          </div>
          <div className={`form__main-address flex flex-col ${this.doorstepCollectionErrorType ? 'p-4 border-4 border-red-500 rounded-xl' : ''}`}>
            <label className='block mb-2 text-sm font-medium text-gray-900'> <>{i18n.t('Address')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
            <input id='mainAddressInput'
              className='p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
              type='text'
              onChange={e => this._updateFormDataHandler(e.target.value, 'address')}
              value={this.state.formData.address ?? ''}
              maxLength={35}
            />
            <div style={{ visibility: this.state.isAddressValid || this.state.formData.address === null ? 'hidden' : 'visible' }}>
              <ValidationError errorMessage={i18n.t('Address is required')} />
            </div>
          </div>
          <div className='form__location-container flex items-center gap-4'>
            <div className='form__postal-code flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900 '> <>{i18n.t('Postal Code')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></>  </label>
              <input id='locationInput'
                className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='text'
                onChange={e => this._updateFormDataHandler(e.target.value, 'postalCode')}
                value={this.state.formData.postalCode ?? ''}
              />
              <div style={{ visibility: this.state.isPostalCodeValid || this.state.formData.postalCode === null ? 'hidden' : 'visible' }}>
                <ValidationError errorMessage={i18n.t('PostalCode is required')} />
              </div>
            </div>
            <div className='form__city flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900 '> <>{i18n.t('City')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></>  </label>
              <input id='cityInput'
                className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='text'
                onChange={e => this._updateFormDataHandler(e.target.value, 'city')}
                value={this.state.formData.city ?? ''}
              />
              <div style={{ visibility: this.state.isCityValid || this.state.formData.city === null ? 'hidden' : 'visible' }}>
                <ValidationError errorMessage={i18n.t('City is required')} />
              </div>
            </div>
          </div>
          <div className='form__province-container flex items-center gap-4'>
            <div className='form__province flex-1'>
              <label className='block mb-2 text-sm font-medium text-gray-900 '> <>{i18n.t('Province')}</> </label>
              <input id='provinceInput'
                className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                type='text'
                onChange={e => this._updateFormDataHandler(e.target.value, 'province')}
                value={this.state.formData.province ?? ''}
              />
            </div>
            <div className='flex-1'> </div>
          </div>
          <div className='form__device-selection-container flex flex-row justify-between gap-8'>
            <div className='form__product-category flex flex-col flex-1 justify-between'>
              <label className='block mb-2 text-sm font-bold text-gray-900 w-72'> <>{i18n.t('What kind of device do you want to recycle?')}</> </label>
              <select name='DeviceSeletor'
                id='deviceSelector'
                value={JSON.stringify(this.state.formData.productCategory)}
                className='p-2 w-72 text-center bg-gray-50 rounded-xl border-1'
                onChange={this._handleProductCategoryChange}
              >
                {
                  this.state.allProductCategories.map((productCategory) => (
                    <option key={productCategory.id} value={JSON.stringify(productCategory)}>
                      <>{i18n.t(productCategory.name)}</>
                    </option>
                  ))
                }
              </select>
            </div>
            <div className='form__quantity flex flex-col flex-1 justify-between items-center'>
              <label className='block mb-2 text-sm font-bold text-gray-900'><>{i18n.t('Quantity')}</></label>
              <label className='p-1'><>{this.state.formData.deviceAmount}</></label>
            </div>
            <div className='form__manufacturer-selection flex flex-col flex-1 justify-between'>
              <label className='block mb-2 text-sm font-bold text-gray-900'> <>{i18n.t('Which Manufacturer?')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <div className={isRightToLeft(this.state.formData.language.languageCode) ? 'manufacturer-selection__radio-options flex divide-x-2 justify-end' : 'manufacturer-selection__radio-options flex divide-x-2'} dir='ltr'>
                <div className='radio-options__apple items-center'>
                  <input type='radio' onChange={this._handleManufacturerChange} checked={this.state.formData.manufacturer === Manufacturer.APPLE} value={JSON.stringify(Manufacturer.APPLE)} id='Apple' name='radio-manufacturer' className='btnSimulation w-4 h-4 checked:border-blue-500' />
                  <label htmlFor='Apple' dir={isRightToLeft(this.state.formData.language.languageCode) ? 'ltr' : 'ltr'} className='py-2 p-2 w-full text-sm font-medium text-black border-l-2 border-t-2 border-b-2 rounded-l-xl  border-gray-200 bg-gray-50'><>{i18n.t('Apple')}</></label>
                </div>
                <div className='radio-options__other items-center'>
                  <input type='radio' onChange={this._handleManufacturerChange} checked={this.state.formData.manufacturer === Manufacturer.OTHER} value={JSON.stringify(Manufacturer.OTHER)} id='Other' name='radio-manufacturer' className='btnSimulation w-4 h-4 checked:border-blue-500' />
                  <label htmlFor='Other' dir={isRightToLeft(this.state.formData.language.languageCode) ? 'ltr' : 'ltr'} className='py-2 p-2 text-sm font-medium text-black border-r-2 border-t-2 border-b-2 rounded-r-xl border-gray-200 bg-gray-50'><>{i18n.t('Other')}</></label>
                </div>
              </div>
            </div>
          </div>
          <div className={`form__doorstep-collection-time flex-col ${this.doorstepCollectionErrorType ? 'p-4 border-4 border-red-500 rounded-xl' : ''}`} style={{ display: this.state.isDoorstepCollection ? 'flex' : 'none' }}>
            <div className='flex flex-row justify-between gap-4'>
              <div className='form_datepicker flex flex-col w-1/2'>
                <label className='block mb-2 text-sm font-bold text-gray-900'>
                  <>{i18n.t('Desired pick-up date')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></>
                </label>
                <DatePicker
                  className={'datepicker text-center w-full p-2 text-gray-900 bg-gray-50 rounded-xl focus:ring-blue-500 focus:border-blue-500 border border-gray-300'}
                  selected={new Date(this.state.formData.startDateTime)}
                  showYearDropdown={true}
                  yearDropdownItemNumber={5}
                  scrollableYearDropdown
                  minDate={new Date(this.initialDoorstepCollectionTimes.startTime)}
                  dateFormat='dd/MM/yyyy'
                  filterDate={this._isWeekday}
                  onChange={this._handleDoorstepCollectionDayChange}
                  locale={this.state.formData.language.languageCode + this.state.formData.country.countryCode}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <div style={{ visibility: this.state.isDoorstepCollectionValid ? 'hidden' : 'visible' }}>
                  <ValidationError errorMessage={i18n.t('Pickup-Date not valid')} />
                </div>
              </div>
              <div className='form__timepicker flex flex-col'>
                <label className='block mb-2 text-sm font-bold text-gray-900'> <>{i18n.t('Time')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
                <div className={isRightToLeft(this.state.formData.language.languageCode) ? 'timepicker__radio-options flex divide-x-2 justify-end' : 'timepicker__radio-options flex divide-x-2'} dir='ltr'>
                  <div className='radio-options__timeslot1 items-center'>
                    <input type='radio' onChange={e => (this._handleDoorstepCollectionTimeslotChange(e))} checked={this.state.doorstepCollectionTimeslot === 1} value={1} id='Timeslot1' name='radio-timeslot' className='btnSimulation w-4 h-4' />
                    <label htmlFor='Timeslot1' dir={isRightToLeft(this.state.formData.language.languageCode) ? 'ltr' : 'ltr'} className='py-2 p-2 w-full text-sm font-medium text-black border-l-2 border-t-2 border-b-2 rounded-l-xl  border-gray-200 bg-gray-50'><>{i18n.t('DoorstepCollection_PickupTime_1')}</></label>
                  </div>
                  <div className='radio-options__timeslot2 items-center'>
                    <input type='radio' onChange={e => (this._handleDoorstepCollectionTimeslotChange(e))} checked={this.state.doorstepCollectionTimeslot === 2} value={2} id='Timeslot2' name='radio-timeslot' className='btnSimulation w-4 h-4' />
                    <label htmlFor='Timeslot2' dir={isRightToLeft(this.state.formData.language.languageCode) ? 'ltr' : 'ltr'} className='py-2 p-2 text-sm font-medium text-black border-r-2 border-t-2 border-b-2 rounded-r-xl border-gray-200 bg-gray-50'><>{i18n.t('DoorstepCollection_PickupTime_2')}</></label>
                  </div>
                </div>
                <div style={{ visibility: this.state.isDoorstepCollectionValid ? 'hidden' : 'visible' }}>
                  <ValidationError errorMessage={i18n.t('Pickup-Date not valid')} />
                </div>
              </div>
            </div>

          </div>

          <div className='form__separator flex justify-center items-center gap-4 my-8 '>
            <span className='h-1 bg-gray-300 w-full rounded-xl' />
          </div>
          <div className='form__policy-terms-container justify-center flex flex-col gap-2 '>
            <div className='form__privacy-policy'>
              <label className='block text-sm font-bold text-gray-900'> <>{i18n.t('Consent - Privacy Policy')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <div className='flex items-center mb-4'>
                <input id='policyCheckbox' type='checkbox' onChange={e => (this._handleConsentCheckbox(e.target.checked))} name='checkbox' className='w-4 h-4 mt-4' />
                <label className='flex-1 ml-4 text-sm text-left mt-4 text-gray-900 break-words'>
                  <>{i18n.t('I understand that the information I have provided will be sent to TES, and will be used by TES solely for the purpose of facilitating the Apple Trade In program and other services I may specifically request. See the')}</> <a href='https://www.tes-amm.com/data-privacy#!/' target='_blank' rel='noreferrer'><>{i18n.t('TES Privacy Policy')}</></a>.
                </label>
              </div>
            </div>
            <div className='form__terms-container'>
              <label className='text-sm font-bold text-gray-900'> <>{i18n.t('Consent - Terms & Conditions')} <label className='text-xs text-gray-500'>(<>{i18n.t('required')}</>)</label></> </label>
              <div className='form__tes-terms flex items-center'>
                <input id='termsCheckbox' type='checkbox' onChange={e => (this._handleTesTermsCheckbox(e.target.checked))} name='checkbox' className='w-4 h-4 mt-4' />
                <label className='ml-4 text-sm text-left mt-4 text-gray-900 break-words'>
                  <>{i18n.t('I agree to')}</> <a href={`/content/Terms.${currentCulture}.html`} target='_blank' rel='noreferrer'><>{i18n.t('TES\'s Terms & Conditions')}</></a>.
                </label>
              </div>
              <div className='form__apple-terms flex items-center'>
                <input id='appleTermsCheckbox' type='checkbox' onChange={e => (this._handleAppleTermsCheckbox(e.target.checked))} name='checkbox' className='w-4 h-4 mt-4' />
                <label className='ml-4 text-sm text-left mt-4 text-gray-900 break-words'>
                  <>{i18n.t('I agree to')}</> <a href={`${i18n.t('LinkToAppleTerms')}`} target='_blank' rel='noreferrer'><>{i18n.t('Apple\'s Terms & Conditions')}</></a>.
                </label>
              </div>
              <div className='form__ups-terms flex items-center'>
                <input id='upsTermsCheckbox' type='checkbox' onChange={e => (this._handleUPSTermsCheckbox(e.target.checked))} name='checkbox' className='w-4 h-4 mt-4' />
                <label className='ml-4 text-sm text-left mt-4 text-gray-900 break-words'>
                  <>{i18n.t('I agree to')}</> <a href={`${i18n.t('LinkToUPSTerms')}`} target='_blank' rel='noreferrer'><>{i18n.t('UPS\'s Terms & Conditions')}</></a>.
                </label>
              </div>
              <div className='form__submit-button flex items-center justify-end mt-4 mb-12'>
                <button id='submitButton'
                  className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl'
                  onClick={this._submitForm.bind(this)}
                  disabled={!this.state.isFormValid}
                >
                  <>{i18n.t('Submit')}</>
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className='body__faq flex flex-col justify-center gap-4 mx-auto w-[600px] mt-8 pb-8' id='faq'>
          <h2>{i18n.t('FAQ_Main_Title')}</h2>
          <h3>{i18n.t('FAQ_Second_Title')}</h3>
          <p>{i18n.t('FAQ_Second_Text_Part_One')}</p>
          <p>{i18n.t('FAQ_Second_Text_Part_Two')}</p>

          <h3>{i18n.t('FAQ_Third_Title')}</h3>
          <p>{i18n.t('FAQ_Third_Text')}</p>

          <h3>{i18n.t('FAQ_Fourth_Title')}</h3>
          <p>{i18n.t('FAQ_Fourth_Text')}</p>

          <h3>{i18n.t('FAQ_Fifth_Title')}</h3>
          <p>{i18n.t('FAQ_Fifth_Text')}</p>

          <h3>{i18n.t('FAQ_Sixth_Title')}</h3>
          <p>{i18n.t('FAQ_Sixth_Text_Part_One')}</p>
          <ul>
            <li>{i18n.t('FAQ_Sixth_Text_Part_One_Table_One')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_One_Table_Two')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_One_Table_Three')}</li>
          </ul>
          <p>{i18n.t('FAQ_Sixth_Text_Part_Two')}</p>
          <ul>
            <li>{i18n.t('FAQ_Sixth_Text_Part_Two_Table_One')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_Two_Table_Two')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_Two_Table_Three')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_Two_Table_Four')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_Two_Table_Five')}</li>
            <li>{i18n.t('FAQ_Sixth_Text_Part_Two_Table_Six')}</li>
          </ul>
          <p>{i18n.t('FAQ_Sixth_Text_Part_Three')}</p>

          <h3>{i18n.t('FAQ_Seventh_Title')}</h3>
          <p>{i18n.t('FAQ_Seventh_Text')}</p>

          <h3>{i18n.t('FAQ_Eight_Title')}</h3>
          <p>{i18n.t('FAQ_Eight_Text_Part_One')}</p>
          <ul>
            <li><a href={i18n.t('iPhoneiPadLink') ?? '#'} target='_blank' rel='noreferrer'>{i18n.t('FAQ_Eight_Text_Part_One_Table_One')}</a></li>
            <li><a href={i18n.t('MacLink') ?? '#'} target='_blank' rel='noreferrer'>{i18n.t('FAQ_Eight_Text_Part_One_Table_Two')}</a></li>
            <li><a href={i18n.t('WatchLink') ?? '#'} target='_blank' rel='noreferrer'>{i18n.t('FAQ_Eight_Text_Part_One_Table_Three')}</a></li>
          </ul>
          <p>{i18n.t('FAQ_Eight_Text_Part_Two')}</p>

          <h3>{i18n.t('FAQ_Ninth_Title')}</h3>
          <p>{i18n.t('FAQ_Ninth_Text')}</p>

          <h3>{i18n.t('FAQ_Tenth_Title')}</h3>
          <p>{i18n.t('FAQ_Tenth_Text')}</p>

          <h3>{i18n.t('FAQ_Eleventh_Title')}</h3>
          <p>{i18n.t('FAQ_Eleventh_text_Part_One')}</p>
          <ul>
            <li>{i18n.t('FAQ_Eleventh_Text_Part_One_Table_One')}</li>
            <li>{i18n.t('FAQ_Eleventh_Text_Part_One_Table_Two')}</li>
            <li>{i18n.t('FAQ_Eleventh_Text_Part_One_Table_Three')}</li>
            <li>{i18n.t('FAQ_Eleventh_Text_Part_One_Table_Four')}</li>
          </ul>
          <p>{i18n.t('FAQ_Eleventh_Text_Part_Two')}</p>

          <h3>{i18n.t('FAQ_Twelveth_Title')}</h3>
          <ul>
            <li><a href={i18n.t('iPhoneiPadLink') ?? '#'} target='_blank' rel='noreferrer'>{i18n.t('FAQ_Twelveth_Table_One')}</a></li>
            <li><a href={i18n.t('MacLink') ?? '#'} target='_blank' rel='noreferrer'>{i18n.t('FAQ_Twelveth_Table_Two')}</a></li>
            <li><a href={i18n.t('WatchLink') ?? '#'} target='_blank' rel='noreferrer'>{i18n.t('FAQ_Twelveth_Table_Three')}</a></li>
          </ul>

          <h3>{i18n.t('FAQ_Thirteenth_Title')}</h3>
          <p>{i18n.t('FAQ_Thirteenth_Text')}</p>

          <h3>{i18n.t('FAQ_Fourteenth_Title')}</h3>
          <p>{i18n.t('FAQ_Fourteenth_Text')}</p>

          <h3>{i18n.t('FAQ_Fifteenth_Title')}</h3>
          <p>{i18n.t('FAQ_Fifteenth_Text')}</p>
        </div>
      </div>
    );
  }

  private _submitForm(): void {
    const processModelId: string = (this.props.config as Config).processEngineConfig.processModelId;
    const payload = {
      consumerRecyclingFormData: this.state.formData,
      doorstepCollectionErrorRetryCount: this.props.payload?.DoorstepCollectionErrorRetryCount ?? 0,
      useFrontend: true,
    };

    this.props.startProcess(processModelId, payload);
  }

  private _isWeekday(date: Date): boolean {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

  private _setinitialDoorstepCollectionTimes(): void {
    const initialDate = new Date();
    const daysAhead = 5;
    initialDate.setHours(daysAhead * 24);

    if (!this._isWeekday(initialDate)) {
      const day = initialDate.getDay();
      (day === 0)
        ? initialDate.setHours(new Date().getDay() + 24)
        : initialDate.setHours(new Date().getDay() + 48);
    }

    const datestring = initialDate.toISOString();
    const initialStartEndTime = this._getTimeSlotTime(datestring, 1);

    this.initialDoorstepCollectionTimes = {
      startTime: initialStartEndTime.startDateTime,
      endTime: initialStartEndTime.endDateTime,
    };
  }

  private _handleDoorstepCollectionDayChange(date: Date): void {
    const pickupYear = date.getFullYear();
    const pickupMonth = date.getMonth();
    const pickupDay = date.getDate();

    const startDateTime = new Date(this.state.formData.startDateTime);
    const startHours = startDateTime.getHours();
    const startMinutes = startDateTime.getMinutes();
    const startSeconds = startDateTime.getSeconds();
    const startMilliseconds = startDateTime.getMilliseconds();
    const startDate = new Date(pickupYear, pickupMonth, pickupDay, startHours, startMinutes, startSeconds, startMilliseconds).toISOString();

    const endDateTime = new Date(this.state.formData.endDateTime);
    const endHours = endDateTime.getHours();
    const endMinutes = endDateTime.getMinutes();
    const endSeconds = endDateTime.getSeconds();
    const endMilliseconds = endDateTime.getMilliseconds();
    const endDate = new Date(pickupYear, pickupMonth, pickupDay, endHours, endMinutes, endSeconds, endMilliseconds).toISOString();

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        startDateTime: startDate,
        endDateTime: endDate,
      },
    }));
  }

  private _handleDoorstepCollectionTimeslotChange(e: ChangeEvent): void {
    const timeslot: number = JSON.parse((e.target as HTMLInputElement).value);
    const timeslotTime = this._getTimeSlotTime(this.state.formData.startDateTime, timeslot);

    this.setState(prevState => ({
      doorstepCollectionTimeslot: timeslot,
      formData: {
        ...prevState.formData,
        startDateTime: timeslotTime.startDateTime,
        endDateTime: timeslotTime.endDateTime,
      },
    }));
  }

  private _handleManufacturerChange(e: ChangeEvent): void {
    const manufacturer: Manufacturer = JSON.parse((e.target as HTMLInputElement).value);
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        manufacturer: manufacturer,
      },
    }));
  }

  private _handleProductCategoryChange(e: ChangeEvent): void {
    const productCategory = JSON.parse((e.target as HTMLInputElement).value);

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        productCategory: productCategory,
      },
    }));
  }

  private _handleConsentCheckbox(value: boolean): void {
    this.setState({
      isConsentValid: value,
    }, () => this._checkIsFormValid());
  }

  private _handleTesTermsCheckbox(value: boolean): void {
    this.setState({
      isTesTermsValid: value,
    }, () => this._checkIsFormValid());
  }

  private _handleAppleTermsCheckbox(value: boolean): void {
    this.setState({
      isAppleTermsValid: value,
    }, () => this._checkIsFormValid());
  }

  private _handleUPSTermsCheckbox(value: boolean): void {
    this.setState({
      isUPSTermsValid: value,
    }, () => this._checkIsFormValid());
  }

  private _checkIsFormValid(): void {
    const isFormValid =
      this.state.isFirstnameValid &&
      this.state.isLastnameValid &&
      this.state.isEmailValid &&
      this.state.isAddressValid &&
      this.state.isPhoneValid &&
      this.state.isCityValid &&
      this.state.isPostalCodeValid &&
      this.state.isConsentValid &&
      this.state.isTesTermsValid &&
      this.state.isAppleTermsValid &&
      this.state.isUPSTermsValid &&
      this.state.isDoorstepCollectionValid;

    this.setState({
      isFormValid: isFormValid,
    });
  }

  private _checkIsEmailValid(email: string): boolean {
    const regExp = new RegExp('^\\S+@\\S+\\.\\S+$');

    return regExp.test(email);
  }

  private _checkIsPhoneValid(phone: string): boolean {
    const regExp = new RegExp('^[0-9]\\d{0,14}$');

    return regExp.test(phone);
  }

  private _isNotEmpty(value: string | undefined): boolean {
    return value !== '' && value !== undefined && value !== null;
  }

  private _updateFormDataHandler(value: string, key: string): void {
    if (key === 'firstName') {
      const isNameValid: boolean = this._isNotEmpty(value);
      this.setState({ isFirstnameValid: isNameValid }, () => this._checkIsFormValid());
    }

    if (key === 'lastName') {
      const isLastnameValid: boolean = this._isNotEmpty(value);
      this.setState({ isLastnameValid: isLastnameValid }, () => this._checkIsFormValid());
    }

    if (key === 'email') {
      const isEmailValid: boolean = this._checkIsEmailValid(value);
      this.setState({ isEmailValid: isEmailValid }, () => this._checkIsFormValid());
    }

    if (key === 'phone') {
      value = value.replace(/[^0-9]/g, '');
      const isPhoneValid: boolean = this._checkIsPhoneValid(value);
      this.setState({ isPhoneValid: isPhoneValid }, () => this._checkIsFormValid());
    }

    if (key === 'address') {
      const isAddressValid: boolean = this._isNotEmpty(value);
      this.setState({ isAddressValid: isAddressValid }, () => this._checkIsFormValid());
    }

    if (key === 'city') {
      const isCityValid: boolean = this._isNotEmpty(value);
      this.setState({ isCityValid: isCityValid }, () => this._checkIsFormValid());
    }

    if (key === 'postalCode') {
      const isPostalCodeValid: boolean = this._isNotEmpty(value);
      this.setState({ isPostalCodeValid: isPostalCodeValid }, () => this._checkIsFormValid());
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    }, () => this._checkIsFormValid());
  }

  private _setIsDoorStepCollection(): void {
    const isDoorstepCollection = this.state.formData.country.logisticType.name === 'AUTOMATIC_DOORSTEP';

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
      },
    }));

    this.setState({
      isDoorstepCollection: isDoorstepCollection,
    });

    if (!isDoorstepCollection) {
      this.setState({
        isDoorstepCollectionValid: true,
      });
    }
  }

  private _getTimeSlotTime(dateString: string, timeSlot: number): { startDateTime: string, endDateTime: string } {
    const date = new Date(dateString);
    const startDateTimeDate = new Date(date);
    const endDateTimeDate = new Date(date);

    if (timeSlot === 1) {
      startDateTimeDate.setHours(8, 0, 0, 0);
      endDateTimeDate.setHours(12, 0, 0, 0);
    } else if (timeSlot === 2) {
      startDateTimeDate.setHours(13, 0, 0, 0);
      endDateTimeDate.setHours(18, 0, 0, 0);
    } else {
      throw new Error('Invalid time slot. Please provide either 1 or 2.');
    }

    const startDateTime: string = startDateTimeDate.toISOString();
    const endDateTime: string = endDateTimeDate.toISOString();

    return { startDateTime, endDateTime };
  }

  private _toggleDoorstepCollectionErrorModal(): void {
    this.setState(prevState => ({
      showDoorstepCollectionErrorModal: !prevState.showDoorstepCollectionErrorModal,
    }));
  }

  private _isAddressError(errorCode: number): boolean {
    return (errorCode >= 9500540 && errorCode <= 9500547) ||
      errorCode === 9500704;
  }

  private _isPickupTimeError(errorCode: number): boolean {
    return (errorCode >= 9510110 && errorCode <= 9510114) ||
      errorCode === 9510117 ||
      errorCode === 9510118;
  }

  private _determineErrorType(errorCode: number): DoorstepCollectionError | null {
    if (errorCode === null || errorCode === undefined) {
      return null;
    } else if (this._isAddressError(errorCode)) {
      return DoorstepCollectionError.ADDRESS;
    } else if (this._isPickupTimeError(errorCode)) {
      return DoorstepCollectionError.TIME;
    } else {
      throw new Error(`Unknown UPS code: ${errorCode}`);
    }
  }

  private _registerLanguages(): void {
    registerLocale('deDE', de);
    registerLocale('deBE', de);
    registerLocale('enDE', de);
    registerLocale('enGB', enGB);
    registerLocale('enUS', enUS);
    registerLocale('ptPT', pt);
    registerLocale('plPL', pl);
    registerLocale('frFR', fr);
    registerLocale('frBE', fr);
    registerLocale('trTR', tr);
    registerLocale('nlNL', nl);
    registerLocale('nlBE', nlBE);
    registerLocale('itIT', it);
    registerLocale('arAE', ar);
    registerLocale('arSA', ar);
    registerLocale('esES', es);
  }
}
