import { ApolloError } from '@apollo/client';
import { toast, ToastOptions, ToastContent } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export class ErrorHandler {

  public static handleError(error: Error | unknown): void {
    const networkError = (error as ApolloError).message.includes('Failed to fetch');
    if (networkError) {
      const toastContent: ToastContent = 'Sorry, we were unable to load or save the data. Please check your internet connection and try again later.';
      const toastOptions: ToastOptions = {
        type: 'error',
        autoClose: false,
      };
      toast(toastContent, toastOptions);

      return;
    }

    const invalidPdfError = (error as Error).message.includes('The PDF file');
    if (invalidPdfError) {
      const toastContent: ToastContent = (error as Error).message;
      const toastOptions: ToastOptions = {
        type: 'error',
        autoClose: false,
      };
      toast(toastContent, toastOptions);

      return;
    }

    throw error;
  }
}
