import React from 'react';

import './HeaderComponent.css';
import logo from '../../assets/Images/TESlogo.png';
import { CountrySelector } from '../../features/RecyclingPlatformStartDialog/SelectCountry/CountrySelector/CountrySelector';
import '../../config/main.css';
import i18n from '../../util/i18n';
import { Country, Language } from '../../contracts';
import { HeaderComponentProps } from './contracts';
import { Config } from '../DialogRenderer';

export class HeaderComponent extends React.Component<HeaderComponentProps> {


  constructor(props: HeaderComponentProps) {
    super(props);

    this._getCountryChange = this._getCountryChange.bind(this);
    this._getLanguageChange = this._getLanguageChange.bind(this);
    this._finishedLoading = this._finishedLoading.bind(this);
    
  }

  public render(): JSX.Element {
    let countrySelector: JSX.Element = <></>;

    if (this.props.countryApolloClient &&
      this.props.languageApolloClient &&
      this.props.getCountryChange &&
      this.props.getLanguageChange &&
      this.props.portalLanguageCode &&
      this.props.finishedLoading) {
      countrySelector = (
        <div className='header_country-selection'>
          <CountrySelector
            portalLocation={this.props.portalLocation}
            portalLanguageCode={this.props.portalLanguageCode}
            getCountryChange={this._getCountryChange}
            getLanguageChange={this._getLanguageChange}
            countryApolloClient={this.props.countryApolloClient}
            languageApolloClient={this.props.languageApolloClient}
            finishedLoading={this._finishedLoading}
            config={this.props.config as Config}
          />
        </div>
      );
    }

    return (
      <>
        <header className='header flex justify-between'>
          <div className='header__navbar flex items-center gap-4'>
            <div className='header__tes-Logo inset-0'>
              <a href='/' className='Logo'>
                <img src={logo} alt='tes-logo' loading='lazy' className='scale-75 inset-0' />
              </a>
            </div>
            <div className='navbar__about-Tes text-center ml-4'>
              <a href='https://www.tes-amm.com/our-business#!/' className='font-bold text-black hover:text-blue-600'>
                <>{i18n.t('About TES')}</>
              </a>
            </div>
            <div className='nab'>
              <a href='https://www.tes-amm.com/contact#!/' className='font-bold text-black'>
                <>{i18n.t('Contact')}</>
              </a>
            </div>
          </div>
        </header>
        <div className='Header__Line-Seperation LineSeperator' />
        <div className='Header__Line-Seperation-Background bg-gray-100 flex flex-col h-8 justify-center items-center'>
          {countrySelector}
        </div>
      </>
    );
  }

  private _getCountryChange(country: Country): void {
    if (this.props.getCountryChange) {
      this.props.getCountryChange(country);
    }
  }

  private _getLanguageChange(language: Language): void {
    if (this.props.getLanguageChange) {
      this.props.getLanguageChange(language);
    }
  }

  private _finishedLoading(finishedLoading: boolean): void {
    if (this.props.finishedLoading) {
      this.props.finishedLoading(finishedLoading);
    }
  }

}
