import { Component, MouseEvent } from 'react';
import { Language } from '../../../../contracts';
import { parseLanguageCountryCode } from '../../../../util';
import './LanguageSelector.css';

import i18n from '../../../../util/i18n';
import { LanguageSelectorProps, LanguageSelectorState } from './contracts';

export class LanguageSelector extends Component<LanguageSelectorProps, LanguageSelectorState> {

  constructor(props: LanguageSelectorProps) {
    super(props);

    this.state = {
      currentLanguage: { id: '', languageCode: '', name: '' },
    };

    this.handleLanguageClick = this.handleLanguageClick.bind(this);
  }

  public componentDidMount(): void {
    this.setState({
      currentLanguage: this.props.defaultLanguage,
    });
  }

  public componentDidUpdate(prevProps: Readonly<LanguageSelectorProps>, prevState: Readonly<LanguageSelectorState>, snapshot?: any): void {
    if (prevProps.defaultLanguage !== this.props.defaultLanguage) {
      this.setState({
        currentLanguage: this.props.defaultLanguage,
      });
      this.props.getLanguageChange(this.props.defaultLanguage);
      this.props.finishedLoading(true);
    }
  }

  render(): JSX.Element {
    const languages: Array<JSX.Element> = [];

    this.props.supportedLanguages.forEach((language, index) => {
      languages.push(
        <button
          key={language.id}
          className={this.state.currentLanguage.id === language.id ? 'isSelected' : ''}
          onClick={this.handleLanguageClick}
          value={JSON.stringify(language)}
        >
          {language.name}
        </button>,
      );
      languages.push(<div key={index}>&nbsp;{'/'}&nbsp;</div>);
    });
    languages.pop();

    return (
      <div className='language_select flex justify-center'>
        {languages}
      </div>
    );
  }

  private async handleLanguageClick(e: MouseEvent<HTMLElement>): Promise<void> {
    const value: Language = JSON.parse((e.target as HTMLInputElement).value);
    await i18n.changeLanguage(parseLanguageCountryCode(value.languageCode, this.props.currentCountry.countryCode));

    this.props.getLanguageChange(value);
    this.setState({
      currentLanguage: value,
    });
  }
}

